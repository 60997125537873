<template>
	<div class="col-lg-12">
		<div class="row">
			<div class="col-lg-3 pr-0">
				<div class="chat-container">
					<div class="user-list-header">
						<div class="nav-profile-text d-flex flex-column">
						</div>
						<input placeholder="Pencarian">													
					</div>
					<div class="text-center pt-1 pb-0">
						<label>Complain</label>					
					</div>
					<div class="user-list pt-0" v-chat-scroll>
						<transition-group name="todolist" tag="ul">
							<li v-for="item in listUser" class="done" v-bind:key="item.uuid_users" @click="toChatUser(item)" style="cursor: pointer;">
								<template v-if="item.photo_profile != null">
									<img :src="item.photo_profile" class="mr-2 pull-left" alt="image" style="width: 36px;height: 36px;border-radius: 100%;">
								</template>
								<template v-else>
									<img src="../../assets/images/faces/face1.jpg" class="mr-2 pull-left" alt="image" style="width: 36px;height: 36px;border-radius: 100%;">
								</template>
																
								<template v-if="item.shortname == null">
									<span class="label">Anonymous</span>								
								</template>
								<template v-else>
									<span class="label">{{item.shortname}}</span>								
								</template>

								<template v-if="item.total != 0">
									<h5 class="label ml-5 count">{{item.total}}</h5>									
								</template>								
								<!-- <div class="actions">
									<button class="btn-picto" type="button" v-on:click="markAsDoneOrUndone(item)" v-bind:aria-label="item.done ? 'Undone' : 'Done'" v-bind:title="item.done ? 'Undone' : 'Done'">
										<i aria-hidden="true" class="material-icons">{{ item.done ? 'check_box' : 'check_box_outline_blank' }}</i>
									</button>
									<button class="btn-picto" type="button" v-on:click="deleteItemFromList(item)" aria-label="Delete" title="Delete">
										<i aria-hidden="true" class="material-icons">delete</i>
									</button>
								</div> -->
							</li>
						</transition-group>
					</div>
				</div>
			</div>		
			<div class="col-lg-9 pl-0">
				<div class="chat-container">
					<div class="chat-header">
						<!-- <div class="nav-profile-image">
							<template v-if="form.uuid_users != ''">
								<template v-if="user != []">
									<template v-if="user.photo_profile != null">
										<img :src="user.photo_profile" alt="profile" style="width: 67px;height: 67px;border-radius: 100%;border: 2px solid #fff;">
									</template>
									<template v-else>
										<img src="../../assets/images/faces/face1.jpg" class="mr-2" alt="image" style="width: 67px;height: 67px;border-radius: 100%;border: 2px solid #fff;">
									</template>					
									<span class="login-status online"></span>								
								</template>								
							</template>
						</div>
						<div class="nav-profile-text d-flex flex-column">
							<span class="font-weight-bold mb-2" v-if="user.shortname == null">{{ user.email }}</span>
							<span class="font-weight-bold mb-2" v-else>{{ user.shortname }}</span>							
						</div>						 -->
						<div class="row">
							<div class="col-lg-12">
								<div class="row">
									<div class="form-group col-lg-6">
										<b-row>
											<b-col class="text-left" lg="12"><label>Fullname</label></b-col>
										</b-row>                    
										<input type="text" class="form-control form-control-lg" :value="header.fullname" disabled="disabled">
									</div>

									<div class="form-group col-lg-6">
										<b-row>
											<b-col class="text-left" lg="12"><label>Subject</label></b-col>
										</b-row>                    
										<input type="text" class="form-control form-control-lg" :value="header.subject" disabled="disabled">
									</div>

									<div class="form-group col-lg-12">
										<b-row>
											<b-col class="text-left" lg="12"><label>Desc</label></b-col>
										</b-row>                    
										<textarea class="form-control form-control-lg" :value="header.desc" rows="5" disabled="disabled"></textarea>										
									</div>                  
								</div>
							</div>
							<b-col class="mb-3 mb-xl-0" lg="12">
								<b-input-group>
									<b-button
										variant="primary"
										:href="header.file"
										target="_blank"
										>Download
									</b-button>
								</b-input-group>
							</b-col>							
						</div>						
				
					</div>
					<div class="chat-messages" v-chat-scroll>
						<div v-for="(message, index) in messages" :key="index">
							<template v-if="message.position == 'left'">
								<div class="message-left">
									<div class="message-bubble">
										<span class="message-text mr-2">{{ message.text }}</span>
										<span class="message-time">{{ message.time }}</span>
									</div>
								</div>					
							</template>
							<template v-else-if="message.position == 'right'">
								<div class="message-right">
								<div class="message-bubble">
									<span class="message-text mr-2">{{ message.text }}</span>
									<span class="message-time">{{ message.time }}</span>
								</div>
							</div>					
							</template>				
						</div>
					</div>
					<div class="chat-input">
						<input v-model="newMessage" @keydown.enter="sendMessage" placeholder="Tulis Pesan...">
					</div>
				</div>
			</div>		
		</div>
	</div>
</template>

<script>
import axios from "axios"
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  data() {
    return {
			header: [],
      messages: [],
      newMessage: "",
			form : {},
			user : [],
			listUser: [],
      counter: 0,
      intervalId: null,			
    };
  },
  methods: {
		normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),
    startInterval() {
      this.intervalId = setInterval(this.incrementCounter, 3000);
    },
    stopInterval() {
      clearInterval(this.intervalId);
    },
    incrementCounter() {
      this.counter++;
			this.getAllMessage(localStorage.getItem("chat-complain-uuid-user"),localStorage.getItem("uuid"),localStorage.getItem("handle-complain-uuid-user"))			
			// this.getAllRecentChatUser(localStorage.getItem("uuid"))			
    },
		toChatUser: function(item) {
      localStorage.setItem('chat-complain-uuid-user',item.uuid_users);			
			this.getAllMessage(localStorage.getItem("chat-complain-uuid-user"),localStorage.getItem("uuid"))
			this.getUser(localStorage.getItem("chat-complain-uuid-user"))			
    },				
    sendMessage() {
      if (this.newMessage.trim() === "") return;
      // const currentTime = new Date().toLocaleTimeString();
      // this.messages.push({ text: this.newMessage, time: currentTime });
			this.form.updated_by = localStorage.getItem("uuid")
			this.form.uuid_users_admin = localStorage.getItem("uuid")
			this.form.uuid_users = localStorage.getItem("chat-complain-uuid-user")
			this.form.uuid_workflow_complains = localStorage.getItem("handle-complain-uuid-user")
			this.form.message = this.newMessage
			
			packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
			axios.post(`${packageStore.mainApi}/api/web/common/complain/chat-user`,this.form)
			.then((response) => {
				// console.log(response.data.result.chat);
        this.messages = response.data.result.chat
        if (this.messages.length != 0) {
          for (let index = 0; index < this.messages.length; index++) {
						this.messages[index].text = this.messages[index].message
						this.messages[index].time = this.normalDateTime(this.messages[index].date)						
            this.messages[index].photo_profile = (this.messages[index].photo_profile != null) ? `${packageStore.mainApi}${this.messages[index].photo_profile}` : null
          }          
        }				
				// this.responseAxios(response)
			})
      this.newMessage = "";
			this.form = {}
    },
		getHeader: function (uuid) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/common/complain/${uuid}`,packageGlobals.configAxios)
			.then((response) => {
        this.header = response.data.result[0]
				localStorage.setItem('chat-complain-uuid-user',this.header.uuid_users);
				this.header.file = (this.header.file != null) ? `${packageStore.mainApi}${this.header.file}` : null				
				console.log(this.header);
			})			
		},
    getAllMessage: function (user,admin,uuid_workflow_complains) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/common/complain/chat-admin/list-chat-admin?uuid_users=${user}&read=1&uuid_users_admin=${admin}&uuid_workflow_complains=${uuid_workflow_complains}`,packageGlobals.configAxios)
			.then((response) => {
        this.messages = response.data.result.chat
        if (this.messages.length != 0) {
          for (let index = 0; index < this.messages.length; index++) {
						this.messages[index].text = this.messages[index].message
						this.messages[index].time = this.normalDateTime(this.messages[index].date)						
            this.messages[index].photo_profile = (this.messages[index].photo_profile != null) ? `${packageStore.mainApi}${this.messages[index].photo_profile}` : null
          }          
        }
			})			
    },
    getUser: function (uuid_users) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/user/profile/${uuid_users}`,packageGlobals.configAxios)
			.then((response) => {
				if (response.data.result.length != 0) {
					this.user = response.data.result[0]
					this.user.photo_profile = (this.user.photo_profile != null) ? `${packageStore.mainApi}${this.user.photo_profile}` : null
				}
			})			
    },
		getAllRecentChatUser : function (uuid_users) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/chat-admin/last-chat-admin?uuid_users_admin=${uuid_users}`,packageGlobals.configAxios)
			.then((response) => {
        this.listUser = response.data.result
        if (this.listUser.length != 0) {
          for (let index = 0; index < this.listUser.length; index++) {
            this.listUser[index].photo_profile = (this.listUser[index].photo_profile != null) ? `${packageStore.mainApi}${this.listUser[index].photo_profile}` : null
          }          
        }
			})			
    },
  },
  created() {
    this.startInterval();
  },
  beforeDestroy() {
    this.stopInterval();
  },	
	mounted() {
		this.getAllMessage(localStorage.getItem("chat-complain-uuid-user"),localStorage.getItem("uuid"),localStorage.getItem("handle-complain-uuid-user"))
		// this.getAllRecentChatUser(localStorage.getItem("uuid"))		    		
		// this.getUser(localStorage.getItem("chat-uuid-user"))
		this.getHeader(localStorage.getItem("handle-complain-uuid-user"))
	},  	
};
</script>

<style scoped>
.chat-container {
  /* max-width: 400px; */
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.chat-header {
  background-color: #00b050;
  color: white;
  padding: 10px;
  text-align: center;
}

.user-list-header {
  background-color: #00b050;
  color: white;
  padding: 10px;
  text-align: center;
	padding: 41px 0px;
}

.chat-messages {
  padding: 10px;
	height: 450px;
  max-height: 450px;
  overflow-y: auto;
}

.user-list {
  padding: 10px;
	height: 447px;
  max-height: 447px;
  overflow-y: auto;
	margin:0rem auto;
	padding:2rem 3rem 3rem;
	max-width:500px;
	/* background:#fff; */
	color:#0a0a0a;
	border-radius: 5px;
}

.message-left {
  display: flex;
  /* justify-content: flex-end; */
  margin-bottom: 10px;
}

.message-right {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.message-bubble {
  background-color: #e1e1e1;
  padding: 8px 12px;
  border-radius: 10px;
  max-width: 70%;
}

.message-text {
  font-size: 14px;
}

.message-time {
  font-size: 12px;
  color: #999;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
}

input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 14px;
}

input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.user-list ul {
	margin-top:0px;
	list-style:none;
}

.user-list li {
	display:flex;
	margin:0 -4rem 7px;
	padding:12px 1rem;
	/* justify-content:space-between; */
	align-items:center;
	background:#FFEB3B;
}

.user-list h1 span {
	display:block;
	font-size:0.8rem;
	margin-bottom:0.7rem;
	margin-left:3px;
	margin-top:0.2rem;   
}

.count {
	display: block;
	width: auto;
	line-height: 26px;
	font-size: 14px;
	font-weight: 900;
	font-family: helvetica, sans-serif;
	color: #fff;
	text-decoration: none;
	text-align: center;
	border-radius: 100%;
	background-color: #00b050;
	border: 1px solid #bcbcbc;
	color: #fff;
	padding: 0px 7px 0 0;
	font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, sans-serif;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}
.count:before {
	float: left;
	margin-top: 4px;
	margin-left: 3px;
	display: block;
	width: auto;
	height: auto;
	content: ' ';
	-webkit-transform: skew(-35deg, 0);
	transform: skew(-35deg, 0);
	background-color: #00b050;
	border-left: 1px solid #aaa;
}
.count:after {
	position: relative;
	margin-top: -8px;
	margin-bottom: 4px;
	margin-left: -3px;
	margin-right: auto;
	display: block;
	width: 2px;
	height: 3px;
	content: ' ';
	-moz-transform: skew(35deg, 0);
	-ms-transform: skew(35deg, 0);
	-webkit-transform: skew(35deg, 0);
	transform: skew(35deg, 0);
	background-color: #efefef;
	border-left: 1px solid #aaa;
}

</style>